// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
