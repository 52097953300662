@use "_vars" as *;
@use "_mquery" as mq;
@use "_clearfix" as cfix;

/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;
    background-color: $grey-color;
    background-image: url(/images/mixer_horiz.jpg);
    background-repeat: no-repeat;
    background-position: center;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;

    padding: 0 1em 0 1em;
    background: #555;
    border: 1px solid black;

    float: left;

    &,
    &:visited {
        color: $header-text-color;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    padding: 0 1em 0 1em;
    background: #555;
    border: 1px solid black;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $header-text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include mq.media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
            color: $text-color;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: calc($spacing-unit / -2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include mq.media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include mq.media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Index page lyrics
 */
#welcome + p + blockquote {
    /* margin-left: 128px; */
}

/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include mq.media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include mq.media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include mq.media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include mq.media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

/**
 * Resume
 */
span.my-name {
    font-size: 2em;
    font-weight: bold;
}
div.versions {
    font-size: 0.8em;
    font-style: italic;
}
span.company {
    font-weight: bold
}
span.job-client {
    font-weight: bold;
    text-align: left;
}
span.job-skill {
    font-weight: bold;
    text-align: left;
}
span.univ {
    font-weight: bold
}
span.prof-org {
    font-weight: bold
}
span.proj {
    font-weight: bold
}
span.mag-name {
    font-style: italic
}
div.job-head {
    clear: both;
    float: none;
    width: 100%;
}
div.job-title {
    font-weight: bold;
    font-stile: italic;
    float: left;
}
div.job-place {
    float: right;
}

/**
 * Emacs tips and tricks
 */
.disabled {
    color: #888;
}

/**
 * Inline images
 */
.inline-image {
    width: 80%;
    margin-left: 8%;
    border: 1px solid black;
}

/**
 * Blog
 */
 div.post-tags-div {
   float: right;
   background-color: $grey-color-light;
   width: 33%;
   ul {
     margin-left: 1em;
     padding-right: 1em;
   }
}
