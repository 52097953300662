@use "sass:color";

// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$header-text-color: #fdfdfd;
$text-color:        #000;
$background-color:  #fdfdfd;
$brand-color:       #2a7ae2;

$grey-color:       #828282;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;
